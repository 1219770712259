import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardNews.module.scss';
import Image from '../../components/Image';

const CardNews = ({ type, title, text, href, image, firstPublishedAt }) => {
    const Tag = !href ? 'div' : 'a';
    let props = {};

    if (href) {
        props.href = href;
    }

    return (
        <div className={styles['CardNews']}>
            <div className={styles['CardNews__Image']}>
                <Image maxImageWidth={768} {...image} altText={''} />
            </div>
            <Tag {...props} className={styles['CardNews__Inner']}>
                <span className={styles['CardNews__Label']}>
                    {firstPublishedAt}
                </span>
                <h3 className={styles['CardNews__Title']}>{title}</h3>
                <p className={styles['CardNews__Text']}>{text}</p>
                <img
                    src={'/assets/svg/arrow--right-alternative.svg'}
                    alt="Signalisten Logotyp"
                    className={styles['CardNews__Arrow']}
                />
            </Tag>
        </div>
    );
};

CardNews.propTypes = {
    type: PropTypes.string,
    firstPublishedAt: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string,
    image: PropTypes.object,
};

CardNews.defaultProps = {
    type: '',
    firstPublishedAt: '',
    title: '',
    text: '',
    href: '',
    image: {},
};

export default CardNews;
